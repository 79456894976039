import { PreconnectData } from './types'

// preconnect 中无法引用 ../../../util/src，因此在这里抄一份
export const getCommitId = () => document.querySelector('meta[name="motiff-commit-id"]')?.getAttribute('content')
export const getReleaseTag = () =>
    document.querySelector('meta[name="motiff-commit-tag"]')?.getAttribute('content') || getCommitId() || 'unknown'

declare global {
    interface Window {
        preconnectPromise?: Promise<PreconnectData>
        clientId?: number
    }
}

// 该 ts 文件需要独立打包，故而该函数不能抽成 common 函数
const buildPreconnectWsConnectUrl = (docId: string, clientId: number): string => {
    // eslint-disable-next-line no-restricted-globals
    const host = location.host
    const isAbroad = host.includes('.zhenguanyu.com') || host.includes('motiff.com')
    const isTesting = host.includes('.zhenguanyu.com') || host.includes('.yuanfudao.biz')

    let WSS_CONNECT_URL = ''

    if (isTesting) {
        if (!isAbroad) {
            WSS_CONNECT_URL = 'wss://motiff-synergy.yuanfudao.biz/synergy'
        } else {
            WSS_CONNECT_URL = 'wss://wukong-abroad-synergy-test.zhenguanyu.com/synergy'
        }
    } else {
        if (!isAbroad) {
            WSS_CONNECT_URL = 'wss://motiff-synergy.motiff.cn/synergy'
        } else {
            WSS_CONNECT_URL = 'wss://motiff-synergy.motiff.com/synergy'
        }
    }

    return `${WSS_CONNECT_URL}/${docId}?${Object.entries({
        release: getReleaseTag(),
        version: 2,
        // eslint-disable-next-line no-restricted-globals
        beta: /^beta/.test(host),
        clientId: clientId,
    })
        .reduce((ret, [key, value]) => [...ret, ...(!value && value !== 0 ? [] : [`${key}=${value}`])], [] as string[])
        .join('&')}`
}

const tryGetDocId = (): string | undefined => {
    const pathName = window.location.pathname

    let docId = undefined

    if (/.*\/file\/(\w+)$/.test(pathName)) {
        docId = pathName.replace(/.*\/file\/(\w+)$/, '$1')
    }

    return docId
}

/**
 * 预连接 WSS
 * 该脚本的运行生命周期在 HTML 加载后的第一时间
 * 通过提前建联节省 [静态文件加载 + RTT + firstArchive] 的时长
 * 当前策略仅作用于用户在鉴权状态下直接访问编辑器，其余情况均会 miss，走正常建联路径
 * 不做心跳保护
 */

// window 作为连接该脚本和应用的媒介
// eslint-disable-next-line
window.preconnectPromise = new Promise<PreconnectData>(async (resolve) => {
    const docId = tryGetDocId()
    const clientId = new Date().getTime() * 1000 + Math.floor(Math.random() * 1000)
    window.clientId = clientId
    const res: PreconnectData = {
        eventQueue: [],
    }

    // 用户可能从非编辑器界面访问，此时不做提前建联
    if (!docId) {
        // 建联失败
        res.eventQueue.push({
            type: 'failed',
        })
        resolve(res)
        return
    }

    const preconnectFailed = () => {
        console.warn('failed to preconnect!')

        res.eventQueue.push({
            type: 'failed',
        })

        res.socket?.close()
    }

    res.jobStartTime = performance.now()

    // 建立 socket 链接
    res.socket = new WebSocket(buildPreconnectWsConnectUrl(docId, clientId))
    res.socket.binaryType = 'arraybuffer'

    res.socket.onclose = () => {
        preconnectFailed()
    }

    res.socket.onmessage = (data) => {
        res.eventQueue.push({
            type: 'message',
            data,
        })
    }

    resolve(res)
})
